<template>
  <div class="page-header-index-wide" style="padding: 0 16px">
    <a-card
      :bodyStyle="{ padding: '16px 0px', height: '100%' }"
      :style="{ height: '100%' }"
    >
      <div class="account-settings-info-main">
        <div class="account-settings-info-left">
          <a-menu
            mode="inline"
            :style="{ border: '0', width: 'auto' }"
            :selectedKeys="selectedKeys"
            type="inner"
            @openChange="onOpenChange"
          >
            <a-menu-item v-for="item in columns" :key="item.path">
              <router-link :to="{ name: item.name }">
                {{ item.title }}
              </router-link>
            </a-menu-item>
          </a-menu>
        </div>
        <div class="account-settings-info-right">
          <div class="account-settings-info-title">
            {{ companyName }}
          </div>
          <route-view></route-view>
        </div>
      </div>
    </a-card>
  </div>
</template>

<script>
import RouteView from "@/layout/RouteView";
import { getCompanyName } from "@/api/company";

let columns = [
  {
    name: "companyBaseInfo",
    title: "公司详情",
    path: "/company/:companyId/info",
  },
  {
    name: "productManage",
    title: "项目管理",
    path: "/company/:companyId/product-manage",
  },
  {
    name: "teamManage",
    title: "团队管理",
    path: "/company/:companyId/team-manage",
  },
  {
    name: "financeManage",
    title: "融资管理",
    path: "/company/:companyId/finance-manage",
  },
  {
    name: "shareholderManage",
    title: "股东信息",
    path: "/company/:companyId/shareholder-manage",
  },
  {
    name: "recruitManage",
    title: "招聘信息",
    path: "/company/:companyId/recruit-manage",
  },
  {
    name: "patentManage",
    title: "专利信息",
    path: "/company/:companyId/patent-manage",
  },
  {
    name: "newsManage",
    title: "公司新闻",
    path: "/company/:companyId/news-manage",
  },
  {
    name: "standardManage",
    title: "标准制定",
    path: "/company/:companyId/standard-manage",
  },
  {
    name: "governRewardManage",
    title: "政府奖励",
    path: "/company/:companyId/govern-reward-manage",
  },
  {
    name: "licenseManage",
    title: "专项证照",
    path: "/company/:companyId/license-manage",
  },
  {
    name: "contestManage",
    title: "比赛信息",
    path: "/company/:companyId/contest-manage",
  },
  {
    name: "exhibitionManage",
    title: "展会信息",
    path: "/company/:companyId/exhibition-manage",
  },
  {
    name: "roadshowManage",
    title: "路演信息",
    path: "/company/:companyId/roadshow-manage",
  },
  {
    name: "topListManage",
    title: "榜单信息",
    path: "/company/:companyId/top-list-manage",
  },
  {
    name: "venturecampManage",
    title: "创业营信息",
    path: "/company/:companyId/venturecamp-manage",
  },
];

export default {
  name: "account",
  components: {
    RouteView,
  },
  data() {
    return {
      columns,
      openKeys: [],
      selectedKeys: [],
      companyName: "",
      companyId: "",
    };
  },
  created() {
    this.companyId = this.$route.path.split("/")[2];
    getCompanyName({ id: this.companyId }).then((res) => {
      if (res.code === 0) {
        this.companyName = res.data;
      }
    });
  },
  mounted() {
    this.updateMenu();
  },
  methods: {
    onOpenChange(openKeys) {
      this.openKeys = openKeys;
    },
    updateMenu() {
      const routes = this.$route.matched.concat();
      this.selectedKeys = [routes.pop().path];
    },
  },
  watch: {
    $route() {
      this.updateMenu();
    },
  },
};
</script>

<style lang="less" scoped>
.account-settings-info-main {
  width: 100%;
  display: flex;
  height: 100%;
  overflow: auto;

  &.mobile {
    display: block;

    .account-settings-info-left {
      border-right: unset;
      border-bottom: 1px solid #e8e8e8;
      width: 100%;
      height: 50px;
      overflow-x: auto;
      overflow-y: scroll;
    }
    .account-settings-info-right {
      padding: 20px 40px;
    }
  }

  .account-settings-info-left {
    border-right: 1px solid #e8e8e8;
    width: 224px;
  }

  .account-settings-info-right {
    flex: 1 1;
    padding: 8px 40px;

    .account-settings-info-title {
      color: rgba(0, 0, 0, 0.85);
      font-size: 20px;
      font-weight: 500;
      line-height: 28px;
      margin-bottom: 16px;
      background-color: #e6f7ff;
      width: fit-content;
      padding: 4px 16px;
      font-size: 18px;
      margin-left: -16px;
    }
    .account-settings-info-view {
      padding-top: 12px;
    }
  }
}
</style>
